import React from "react";
import DataTable from "./../../partials/table/DataTables.js";
import { Link } from "react-router-dom";
import conf from "./../../../config.js";
import SelectJs from "./../../partials/Select.js";
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import { Button, Modal } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DatePicker from "react-datepicker";
import SweetAlert from "sweetalert2-react";
import Select from "react-select";
import { Tab, Tabs } from "react-bootstrap";
import { useChecklist } from "react-checklist";
import swal from "sweetalert2";

import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import {
  UserEditSkeleton,
  UserDetailSkeleton,
  UserPrintSkeleton,
  UserButtonLoading,
} from "../../partials/loadings/ComponentLoading.js";
import ModalDropZoneImage from "../../partials/ModalDropZoneImage";


// select style
const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "100px",
    minWidth: "15rem",
  }),
};

export default class Lainnya extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // url api
      url_table: `${conf.uri_backend}api/v1/user/table/LA/`,
      url_count_table: `${conf.uri_backend}api/v1/user/count_table/LA`,
      url_create: `${conf.uri_backend}api/v1/user/create/LA`,
      url_get_user: `${conf.uri_backend}api/v1/user/edit/LA/`,
      url_update: `${conf.uri_backend}api/v1/user/update/LA`,
      url_detail: `${conf.uri_backend}api/v1/user/edit/LA/`,
      url_token_user: `${conf.uri_backend}api/v1/user/update_token/LA`,
      url_generate_all_token: `${conf.uri_backend}api/v1/user/generate-all-token/LA`,
      url_table_token: `${conf.uri_backend}api/v1/user/table_token/LA`,
      url_print_token: `${conf.uri_backend}api/v1/user/generete_token/LA`,
      url_export_data: `${conf.uri_backend}api/v1/user/export/LA/`,
      url_import_data: `${conf.uri_backend}api/v1/user/import/LA`,
      url_save_import_data: `${conf.uri_backend}api/v1/user/import_save/LA/`,
      url_delete: `${conf.uri_backend}api/v1/user/LA/`,
      url_delete_m: `${conf.uri_backend}api/v1/user/user_destroy/LA`,
      url_send_mail: `${conf.uri_backend}api/v1/user/send_mail_activation/LA`,

      // modal
      modal_tambah: false,
      modal_detail: false,
      modal_cetak: false,
      modal_import: false,
      modal_preview_import: false,
      modal_image: false,


      // var data
      id_generate: "",
      nik: "",
      nama: "",
      username: "",
      email: "",
      password: "",
      jenis_kelamin: "",
      tanggal_lahir: "",
      no_telepon: "",
      startDate: new Date(),
      import_file: "",

      // gender array
      jenis_kelamin_text: ["Perempuan", "Laki - laki"],

      // for data
      data_print: [],
      data_detail: [],
      data_preview: [],

      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",

      // session var
      institusi_id_select: "",
      jenjang_id_select: "",
      npsn_id_select: "",
      data_session: [],

      mode_show: 10,
      query: "",
      simpleLoading: false,
      totalUser: "",

      queryToken: "",
      mode_show_token: 10,
      searchToken: "",

      isChecked: false,
      check_item: false,
      checkAll: false,
      loading_check: false,
      selectStatus: "",
      loading_generate_token: false,
    };

    // Edit Function
    this.update = this.update.bind(this);
    this.showModalEdit = this.showModalEdit.bind(this);
    this.hideModalEdit = this.hideModalEdit.bind(this);

    // Create Function
    this.showModalTambah = this.showModalTambah.bind(this);
    this.hideModalTambah = this.hideModalTambah.bind(this);
    this.create = this.create.bind(this);

    // Delete Function
    this.delete = this.delete.bind(this);

    // Bind data
    this.nama = this.nama.bind(this);
    this.username = this.username.bind(this);
    this.email = this.email.bind(this);
    this.password = this.password.bind(this);
    this.nik = this.nik.bind(this);
    this.jenis_kelamin = this.jenis_kelamin.bind(this);
    this.tanggal_lahir = this.tanggal_lahir.bind(this);
    this.no_telepon = this.no_telepon.bind(this);

    // Token Function
    this.generate_token = this.generate_token.bind(this);
    this.generateAllToken = this.generateAllToken.bind(this);
    this.id_generate = this.id_generate.bind(this);

    // Detail Function
    this.showModalDetail = this.showModalDetail.bind(this);
    this.hideModalDetail = this.hideModalDetail.bind(this);

    // Cetak Function
    this.showModalCetak = this.showModalCetak.bind(this);
    this.hideModalCetak = this.hideModalCetak.bind(this);
    this.printToken = this.printToken.bind(this);
    this.showTableToken = this.showTableToken.bind(this);

    // Reset Search Function
    this.resetSearch = this.resetSearch.bind(this);
    this.searchText = this.searchText.bind(this);

    // Export Function
    this.export = this.export.bind(this);

    // Import Function
    this.showModalImport = this.showModalImport.bind(this);
    this.hideModalImport = this.hideModalImport.bind(this);
    this.import_file = this.import_file.bind(this);
    this.import = this.import.bind(this);

    // Preview Import Function
    this.showModalPrevImport = this.showModalPrevImport.bind(this);
    this.hideModalPrevImport = this.hideModalPrevImport.bind(this);
    this.simpanImport = this.simpanImport.bind(this);
    this.showMode = this.showMode.bind(this);
    this.showModeToken = this.showModeToken.bind(this);

    this.pilihSemua = this.pilihSemua.bind(this);
    this.showCount = this.showCount.bind(this);

    this.checkAll = this.checkAll.bind(this);
    this.unCheckAll = this.unCheckAll.bind(this);
    this.deleteMulti = this.deleteMulti.bind(this);
    this.sendMailActivation = this.sendMailActivation.bind(this);

    // Dropzone image
    this.showModalDropzoneImage = this.showModalDropzoneImage.bind(this);
    this.hideModalDropzoneImage = this.hideModalDropzoneImage.bind(this);
  }

  componentDidMount() {
    // get session user
    if (localStorage.getItem("token") !== null) {
      axios
        .post(`${conf.uri_backend}api/v1/auth/sso/user`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          this.setState({ data_session: res.data }, function() {
            axios
              .get(
                `${conf.uri_backend}api/v1/select-institusi/` +
                  this.state.data_session.access_to,
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                this.setState(
                  {
                    institusi_id_select: res.data.id,
                    jenjang_id_select: res.data.m_struktur_jenjang_id,
                    npsn_id_select: res.data.npsn,
                  },
                  function() {
                    this.dt.fetchEntities(
                      `&paginate=${this.state.mode_show}&query=${this.state.query}`
                    );
                    this.showCount();
                  }
                );
              });
          });
        });
    }
  }

  showCount() {
    axios
      .get(
        this.state.url_count_table +
          "?institusi=" +
          this.state.institusi_id_select,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ totalUser: res.data });
      });
  }

  showMode(num) {
    this.setState({ mode_show: num }, function() {
      this.dt.resetPage(
        `&paginate=${this.state.mode_show}&query=${this.state.query}`
      );
      this.dt.loading();
    });
  }

  showModeToken(num) {
    this.setState({ mode_show_token: num }, function() {
      this.dtt.resetPage(
        `&paginate=${this.state.mode_show_token}&search=${this.state.searchToken}`
      );
      this.dtt.loading();
      this.setState({ loading_saveprint: false });
    });
  }

  nama(e) {
    this.setState({ nama: e.target.value });
  }

  username(e) {
    this.setState({ username: e.target.value });
  }
  email(e) {
    this.setState({ email: e.target.value });
  }
  password(e) {
    this.setState({ password: e.target.value });
  }

  nik(e) {
    this.setState({ nik: e.target.value });
  }

  jenis_kelamin(e) {
    this.setState({ jenis_kelamin: e.target.value });
  }

  tanggal_lahir(e) {
    this.setState({
      tanggal_lahir: e.target.value,
      startDate: e.target.value,
    });
  }

  no_telepon(e) {
    this.setState({ no_telepon: e.target.value });
  }

  id_generate(e) {
    this.setState({ id_generate: e.target.value });
  }

  import_file(e) {
    this.setState({ import_file: e.target.files[0] });
  }

  // Edit Funtion
  showModalEdit(id) {
    this.setState({ loading_edit: true, modal_edit: true });
    axios
      .get(this.state.url_get_user + id, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        if (res.data.status == true) {
          var data = res.data.data;
          this.setState({
            id_edit: data.id,
            nik: data.kode_pendaftaran,
            nama: data.nama,
            username: data.username,
            email: data.email,
            password: data.password,
            jenis_kelamin: data.jenis_kelamin,
            tanggal_lahir: data.tanggal_lahir,
            no_telepon: data.no_handphone,
            loading_edit: false,
          });
        }
      });
  }

  hideModalEdit() {
    this.setState({
      modal_edit: false,
      nama: "",
      nik: "",
      jenis_kelamin: "",
      tanggal_lahir: "",
      no_telepon: "",
    });
  }

  alertMessage(e) {
    let message = null;

    switch (e.status) {
      case 422:
        message = e.data.messages[0];
        break;
      case 500:
        message =
          "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
        break;
      case 401:
        message =
          "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 400:
        message =
          "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 408:
        message =
          "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 429:
        message =
          "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 405:
        message =
          "Method Tidak Diperbolehkan, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
    }

    this.setState({
      show_alert: true,
      title_alert: "Gagal",
      icon_alert: "error",
      message_alert: message,
      simpleLoading: false,
    });
  }

  update(event) {
    event.preventDefault();
    this.setState({ simpleLoading: true });

    if (
      this.state.nama === "" ||
      this.state.nik === "" ||
      this.state.jenis_kelamin === "" ||
      this.state.tanggal_lahir === ""
    ) {
      this.setState({
        show_alert: true,
        title_alert: "Gagal",
        icon_alert: "error",
        message_alert: "Isi form dengan benar",
        simpleLoading: false,
      });
    } else {
      axios
        .put(
          this.state.url_update,
          {
            id: this.state.id_edit,
            nama: this.state.nama,
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
            nama: this.state.nama,
            kode_pendaftaran: this.state.nik,
            no_handphone: this.state.no_telepon,
            jenis_kelamin: this.state.jenis_kelamin,
            tanggal_lahir: this.state.tanggal_lahir,
            // updated_at          : new Date(),
            updated_by: this.state.institusi_id_select,
            type: "lainnya",
          },
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          this.setState({
            show_alert: true,
            title_alert: "Berhasil",
            icon_alert: "success",
            message_alert: res.data.messages[0],
            simpleLoading: false,
          });
          this.hideModalEdit();
          this.dt.fetchEntities(
            `&paginate=${this.state.mode_show}&query=${this.state.query}`
          );
        })
        .catch((err) => {
          this.alertMessage(err.response);
        });
    }
  }

  // Create Function
  showModalTambah() {
    this.setState({ modal_tambah: true });
  }

  hideModalTambah() {
    this.setState({
      modal_tambah: false,
      nama: "",
      nik: "",
      jenis_kelamin: "",
      tanggal_lahir: "",
      no_telepon: "",
    });
  }

  create(event) {
    event.preventDefault();

    this.setState({ simpleLoading: true });

    axios
      .post(
        this.state.url_create,
        {
          username: this.state.username,
          email: this.state.email,
          password: this.state.password,
          nama: this.state.nama,
          nama: this.state.nama,
          kode_pendaftaran: this.state.nik,
          no_handphone: this.state.no_telepon,
          jenis_kelamin: this.state.jenis_kelamin,
          tanggal_lahir: this.state.tanggal_lahir,
          user_institusi_id: this.state.institusi_id_select,
          created_at: new Date(),
          created_by: this.state.institusi_id_select,
          type: "lainnya",
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({
          show_alert: true,
          title_alert: "Berhasil",
          icon_alert: "success",
          message_alert: res.data.messages[0],
          simpleLoading: false,
        });
        this.dt.fetchEntities(
          `&paginate=${this.state.mode_show}&query=${this.state.query}`
        );
        this.dt.loadingCreate();
        this.hideModalTambah();
      })
      .catch((err) => {
        this.alertMessage(err.response);
      });
  }

  // Delete Lainnya
  delete(data) {
    this.setState({ loading_delete: true });

    swal
      .fire({
        title: "Perhatian",
        icon: "warning",
        text: "Apakah anda yakin menghapus user lainnya tersebut?",
        showCancelButton: true,
        confirmButtonText: `Iya`,
        cancelButtonText: "Batal",
      })
      .then((result) => {
        if (result.value == true) {
          axios
            .delete(this.state.url_delete + data, {
              headers: { Authorization: tokenApi },
            })
            .then((res) => {
              this.setState({
                show_alert: true,
                title_alert: "Berhasil",
                icon_alert: "success",
                message_alert: res.data.messages[0],
                loading_delete: false,
              });
              this.dt.fetchEntities(
                `&paginate=${this.state.mode_show}&query=${this.state.query}`
              );
            })
            .catch((err) => {
              if (err.response.status == 500) {
                this.setState({
                  show_alert: true,
                  title_alert: "Gagal",
                  icon_alert: "error",
                  message_alert: "Periksa koneksi anda",
                  loading_delete: false,
                });
              }
              this.alertMessage(err.response);
            });
        } else if (result.dismiss == "cancel") {
          this.setState({ loading_delete: false });
        } else if (result.dismiss == "backdrop") {
          this.setState({ loading_delete: false });
        }
      });
  }

  checkAll(e) {
    e.preventDefault();
    this.setState({ loading_check: true }, function() {
      this.setState({ checkAll: true, loading_check: false });
    });
  }

  unCheckAll(e) {
    e.preventDefault();
    this.setState({ loading_check: true }, function() {
      this.setState({ checkAll: false, loading_check: false });
    });
  }

  deleteMulti() {
    this.setState({ loading_delete: true });
    var checkboxes = document.getElementsByName("check_data[]");
    var vals = [];
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    if (vals.length == 0) {
      this.setState({
        show_alert: true,
        title_alert: "Gagal",
        icon_alert: "warning",
        message_alert: "Mohon centang data terlebih dahulu",
        disabledFilter: false,
        checkAll: false,
        loading_delete: false,
      });
    } else {
      swal
        .fire({
          title: "Peringatan",
          icon: "warning",
          text: "Apakah anda yakin menghapus user yang dipilih?",
          showCancelButton: true,
          confirmButtonText: `Iya`,
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value == true) {
            axios
              .post(
                this.state.url_delete_m,
                { id: vals },
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                if (res.data.status == false) {
                  this.setState({
                    show_alert: true,
                    title_alert: "Gagal",
                    icon_alert: "error",
                    message_alert: res.data.messages[0],
                    checkAll: false,
                    loading_delete: false,
                  });
                  this.dt.loading();
                  this.dt.resetPage();
                } else {
                  this.setState({
                    show_alert: true,
                    title_alert: "Berhasil",
                    icon_alert: "success",
                    message_alert: res.data.messages[0],
                    checkAll: false,
                    loading_delete: false,
                  });
                  this.dt.loading();
                  this.dt.resetPage();
                }
              })
              .catch((err) => {
                this.setState({ loading_delete: false });
                this.alertMessage(err.response);
              });
          } else if (result.dismiss == "cancel") {
            this.setState({ loading_delete: false });
          } else if (result.dismiss == "backdrop") {
            this.setState({ loading_delete: false });
          }
        });
    }
  }

  sendMailActivation() {
    this.setState({ loading_mail: true });
    var checkboxes = document.getElementsByName("check_data[]");
    var vals = [];
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    if (vals.length == 0) {
      this.setState({
        show_alert: true,
        title_alert: "Gagal",
        icon_alert: "warning",
        message_alert: "Mohon centang data terlebih dahulu",
        disabledFilter: false,
        loading_mail: false,
        checkAll: false,
      });
    } else {
      swal
        .fire({
          title: "Peringatan",
          text: "Apakah anda yakin mengirim email aktivasi user yang dipilih?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Iya`,
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value == true) {
            axios
              .post(
                this.state.url_send_mail,
                { id: vals },
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                if (res.data.status == false) {
                  this.setState({
                    show_alert: true,
                    title_alert: "Gagal",
                    icon_alert: "error",
                    message_alert:
                      res.data.messages[0] +
                      "Berhasil : " +
                      res.data.berhasil +
                      "Gagal : " +
                      res.data.gagal,
                    loading_mail: false,
                    checkAll: false,
                  });
                  this.dt.loading();
                  this.dt.resetPage();
                } else {
                  this.setState({
                    show_alert: true,
                    title_alert: "Berhasil",
                    icon_alert: "success",
                    message_alert: res.data.messages[0],
                    loading_mail: false,
                    checkAll: false,
                  });
                  this.dt.loading();
                  this.dt.resetPage();
                }
              })
              .catch((err) => {
                this.setState({ loading_mail: false });
                // this.alertMessage(err.response);
              });
          } else if (result.dismiss == "cancel") {
            this.setState({ loading_mail: false });
          } else if (result.dismiss == "backdrop") {
            this.setState({ loading_mail: false });
          }
        });
    }
  }

  // Modal Detail
  showModalDetail(data) {
    this.setState({
      modal_detail: true,
      loading_detail: true,
    });
    axios
      .get(this.state.url_detail + data, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        this.setState({ data_detail: res.data.data, loading_detail: false });
      });
  }

  hideModalDetail() {
    this.setState({ modal_detail: false });
  }

  // Cetak Function
  showModalCetak() {
    this.setState(
      {
        modal_cetak: true,
        queryToken: `&paginate=10&search=`,
      },
      function() {
        this.dtt.fetchEntities(this.state.queryToken);
      }
    );
    // axios.get(`${this.state.url_table_token}/${this.state.institusi_id_select}?paginate=10`, {headers:{Authorization:tokenApi}}).then(res => {
    //   this.setState({ data_print: res.data, loading_print: false });
    // });
  }

  showTableToken() {
    if (this.state.queryToken != "") {
      return (
        <div>
          <DataTable
            ref={(dtt) => {
              this.dtt = dtt;
            }}
            action={{
              jenis_kelamin: (col, data) => {
                var jekel = "";
                if (col.jenis_kelamin == 1) {
                  jekel = "Laki - Laki";
                }

                if (col.jenis_kelamin == 0) {
                  jekel = "Perempuan";
                }

                if (col.jenis_kelamin == null) {
                  jekel = "Belum diketahui";
                }
                return <p className="text-sm">{jekel}</p>;
              },
              pilih: (col, data) => {
                return (
                  <input
                    type="checkbox"
                    name="check_data[]"
                    className="check"
                    id="check_data"
                    value={data.id}
                    defaultChecked={this.state.PilihSemua ? true : null}
                  />
                );
              },
            }}
            url={
              this.state.url_table_token + "/" + this.state.institusi_id_select
            }
            columns={[
              {
                index: "kode_pendaftaran",
                label: "Kode Pendaftaran",
              },
              {
                index: "token",
                label: "token",
              },
              {
                index: "nama",
                label: "nama",
              },
              {
                index: "jenis_kelamin",
                label: "Jenis Kelamin",
              },
              {
                index: "pilih",
                label: "pilih",
              },
            ]}
          />
        </div>
      );
    }
  }

  hideModalCetak() {
    this.setState({
      modal_cetak: false,
      queryToken: "",
      pilihSemua: false,
      searchToken: "",
      mode_show_token: 10,
    });
  }

  printToken(e) {
    e.preventDefault();
    this.setState({ loading_saveprint: true });
    var checkboxes = document.getElementsByName("check_data[]");
    var vals = "";
    var str = "";
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        vals += "," + checkboxes[i].value;
        str = vals.substr(1);
      }
    }

    if (str == "") {
      this.setState({
        show_alert: true,
        title_alert: "Gagal",
        icon_alert: "error",
        message_alert: "Pilih atau centang User terlebih dahulu",
        loading_saveprint: false,
      });
    } else {
      axios
        .post(
          this.state.url_print_token,
          { id: str },
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          this.setState({ loading_saveprint: false });
          var mywindow = window.open("", "new div", "height=400,width=600");
          var jekel = ["Perempuan", "Laki - Laki"];
          res.data.map((person, index) => {
            mywindow.document.write(
              `<html>
              <head>
              </head>
              <body style="display: flex; flex-wrap: wrap; align-content: flex-start;">
                <table style="width:48%; margin-bottom:10px; margin-left: 10px; border-radius: 8px; padding: 4px;" border="1px solid #000000">
                  <tr>
                    <td style="border: 0px solid #000000; font-size: 11px;">KODE PENDAFTARAN</td>
                    <td style="border: 0px solid #000000; font-size: 11px; width: 60%;">: ${
                      person.kode_pendaftaran
                    }</td>
                  </tr>
                  <tr>
                    <td style="border: 0px solid #000000; font-size: 11px;">TOKEN</td>
                    <td style="border: 0px solid #000000; font-size: 11px; width: 60%;">: ${
                      person.token
                    }</td>
                  </tr>
                  <tr>
                    <td style="border: 0px solid #000000; font-size: 11px;">NAMA</td>
                    <td style="border: 0px solid #000000; font-size: 11px; width: 60%;">: ${
                      person.nama
                    }</td>
                  </tr>
                  <tr>
                    <td style="border: 0px solid #000000; font-size: 11px;">JENIS KELAMIN</td>
                    <td style="border: 0px solid #000000; font-size: 11px; width: 60%;">: ${
                      jekel[person.jenis_kelamin]
                    }</td>
                  </tr>
                </table>
              </body>
            </html>`
            );
          });
          mywindow.print();
          mywindow.close();
        })
        .catch((err) => {
          this.alertMessage(err.response);
          this.setState({ loading_saveprint: false });
        });
    }
  }

  // Generate All Token
  generateAllToken() {
    this.setState({ loading_generate_token: true });
    this.setState({ loading_token: true });
    axios
      .post(
        this.state.url_generate_all_token,
        { user_institusi_id: this.state.institusi_id_select },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ loading_generate_token: false });
        this.setState({ loading_token: false });
        if (res.data.status === true) {
          this.dt.loading();
          this.dt.resetPage(
            `&status=${this.state.selectStatus}&paginate=${this.state.mode_show}&query=${this.state.query}`
          );
        } else {
          swal.fire({
            title: "Gagal",
            text: "Ada Kesalahan",
            icon: "error",
          });
        }
        this.setState({ loading_token: false });
      });
  }

  // Generate Token
  generate_token(data) {
    this.setState({ loading_token: data });
    axios
      .post(
        this.state.url_token_user,
        { user_id: data },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ loading_token: false });
        document.getElementById("token" + data + "").innerHTML =
          "" + res.data.token + "";
        var element = document.getElementById("myButton" + data + "");
        element.classList.add("hidden");
      });
  }

  // reset search
  resetSearch(event) {
    event.preventDefault();
    document.getElementById("search").value = "";
    this.dt.fetchEntities(
      `&paginate=${this.state.mode_show}&query=${this.state.query}`
    );
  }

  export(event) {
    this.setState({ loading_export: true });
    event.preventDefault();
    axios({
      url: this.state.url_export_data + this.state.institusi_id_select,
      method: "POST",
      responseType: "blob", // important
      headers: { Authorization: tokenApi },
      data: {
        status: this.state.selectStatus,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        var tanggal = new Date();
        var bulan = tanggal.getMonth() + 1;
        if (bulan < 10 ? (bulan = `0${bulan}`) : "");
        link.setAttribute(
          "download",
          "Lainnya_" +
            tanggal.getDate() +
            "-" +
            bulan +
            "-" +
            tanggal.getFullYear() +
            "_TIME(" +
            tanggal.getHours() +
            "-" +
            tanggal.getMinutes() +
            ").xlsx"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.setState({ loading_export: false });
      })
      .catch((err) => {
        this.alertMessage(err);
        this.setState({ loading_export: false });
      });
  }

  // Modal import
  showModalImport() {
    this.setState({ modal_import: true });
  }

  hideModalImport() {
    this.setState({ modal_import: false });
  }

  import(event) {
    event.preventDefault();

    this.setState({ simpleLoading: true });

    const data = new FormData();
    data.append("file", this.state.import_file);
    axios
      .post(this.state.url_import_data, data, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        if (res.data.status == false) {
          this.setState({
            show_alert: true,
            title_alert: "Gagal",
            icon_alert: "error",
            message_alert: res.data.messages[0],
            simpleLoading: true,
          });
        } else {
          this.showModalPrevImport(res.data.data);
          this.hideModalImport();
        }
      })
      .catch((err) => {
        this.setState({ simpleLoading: false });
        this.alertMessage(err.response);
      });
  }

  // Modal Import Detail
  showModalPrevImport(data) {
    this.setState({
      data_preview: data,
      modal_preview_import: true,
      simpleLoading: false,
    });
  }

  hideModalPrevImport() {
    this.setState({ modal_preview_import: false });
  }

  simpanImport(e) {
    e.preventDefault();
    this.setState({ simpleLoading: true });
    axios
      .post(
        this.state.url_save_import_data + this.state.institusi_id_select,
        { data: this.state.data_preview },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ simpleLoading: false });
        if (res.data.status == false) {
          this.setState({
            show_alert: true,
            title_alert: "Gagal",
            icon_alert: "error",
            message_alert: res.data.messages[0],
            simpleLoading: false,
          });
        } else {
          this.setState({
            show_alert: true,
            title_alert: res.data.title[0],
            icon_alert: res.data.icon[0],
            message_alert: res.data.messages[0],
            simpleLoading: false,
          });
          this.hideModalPrevImport();
          this.dt.fetchEntities(
            `&paginate=${this.state.mode_show}&query=${this.state.query}`
          );
        }
      })
      .catch((err) => {
        this.setState({ simpleLoading: false });
        this.alertMessage(err.response);
      });
  }

  searchText(e) {
    e.preventDefault();
    this.setState(
      { query: document.getElementById("search").value },
      function() {
        this.dt.loading();
        this.dt.resetPage(
          `&paginate=${this.state.mode_show}&query=${this.state.query}`
        );
      }
    );
  }

  changeStatus = (e) => {
    if (e) {
      this.setState(
        {
          selectStatus: e.value,
        },
        function() {
          this.dt.loading();
          this.dt.resetPage(
            `&status=${this.state.selectStatus}&paginate=${this.state.mode_show}&query=${this.state.query}`
          );
        }
      );
    } else {
      this.dt.loading();
      this.dt.resetPage(
        `&paginate=${this.state.mode_show}&query=${this.state.query}`
      );
    }
  };

  pilihSemua() {
    var checkboxes = document.getElementsByClassName("select-all").item(0)
      .checked;
    if (checkboxes == true) {
      this.setState({ PilihSemua: true }, function() {
        this.dtt.loading();
        this.dtt.fetchEntities(this.state.queryToken);
      });
    } else {
      this.setState({ PilihSemua: false }, function() {
        this.dtt.loading();
        this.dtt.fetchEntities(this.state.queryToken);
      });
    }
  }

  // Menampilkan modal dropzone foto profil
  showModalDropzoneImage() {
    this.setState({ modal_image: true });
  }

  // Tutup modal dropzone foto profil
  hideModalDropzoneImage() {
    this.setState({ modal_image: false });
  }

  render() {
    return (
      <div>
        <div className=" shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border sm-container">
          <div className="flex justify-between items-center mt-2  px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              User Lainnya
              <span className="text-gray-500 font-thin block text-sm">
                Daftar User Lainnya.{" "}
                {/* <a href="/sadasd" className="text-blue-600">
                Pelajari Lebih Lanjut ?
              </a> */}
              </span>
            </h1>
            <div className="flex">
              {this.state.institusi_id_select != "" ? (
                <>
                  <button
                    onClick={this.generateAllToken}
                    className="btn-default mx-1 px-4 justify-center font-medium rounded-full text-gray-800 items-center py-2 text-sm"
                    disabled={this.state.loading_generate_token ? true : false}
                  >
                    {this.state.loading_export ? (
                      <UserButtonLoading color="text-gray-700" />
                    ) : (
                      <>
                        <i className="bx bx-doughnut-chart text-lg mr-1"></i>{" "}
                        Generate Token
                      </>
                    )}
                  </button>
                  <button
                    onClick={this.export}
                    className="btn-default mx-1 px-4 justify-center font-medium rounded-full text-gray-800 items-center py-2 text-sm"
                    disabled={this.state.loading_export ? true : false}
                  >
                    {this.state.loading_export ? (
                      <UserButtonLoading color="text-gray-700" />
                    ) : (
                      <>
                        <i className="bx bx-export text-lg mr-1"></i> Export
                      </>
                    )}
                  </button>
                  <button
                    onClick={this.showModalImport}
                    className="btn-default mx-1 px-4 justify-center font-medium rounded-full text-gray-800 items-center py-2 text-sm"
                  >
                    <i className="bx bx-upload text-lg mr-1"></i> Upload
                  </button>
                  <button
                    onClick={this.showModalCetak}
                    className="btn-default mx-1 px-4 justify-center font-medium rounded-full text-gray-800 items-center py-2 text-sm"
                  >
                    <i className="bx bx-printer text-lg mr-1"></i> Cetak
                  </button>
                  <button
                    className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm"
                    onClick={this.showModalTambah}
                  >
                    <i className="bx bx-plus text-lg mr-1"></i>
                    Tambah
                  </button>

                  <button
                    onClick={this.showModalDropzoneImage}
                    className="btn-default mx-1 px-4 justify-center font-medium rounded-full text-gray-800 items-center py-2 text-sm"
                  >
                    <i className="bx bx-upload text-lg mr-1"></i> Upload Foto Profil
                  </button>
                </>
              ) : (
                <>
                  <div className="w-20 px-2">
                    {" "}
                    <Skeleton height={20} className="bg-gray-400" />{" "}
                  </div>
                  <div className="w-20 px-2">
                    {" "}
                    <Skeleton height={20} className="bg-gray-400" />{" "}
                  </div>
                  <div className="w-20 px-2">
                    {" "}
                    <Skeleton height={20} className="bg-gray-400" />{" "}
                  </div>
                  <div className="w-20 px-2">
                    {" "}
                    <Skeleton height={20} className="bg-gray-400" />{" "}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* alert */}
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />

        {/* Modal dropzone foto profil */}
        <ModalDropZoneImage showModal={this.state.modal_image} hideModal={this.hideModalDropzoneImage} type="LA" />

        {/* modal tambah */}
        <Modal show={this.state.modal_tambah} onHide={this.hideModalTambah}>
          <Modal.Header>Tambah Lainnya</Modal.Header>
          <Modal.Body>
            <form onSubmit={this.create}>
              <div className="w-full mt-3">
                <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                  Kode Pendaftaran
                </label>
                <input
                  type="number"
                  min="0"
                  className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                  placeholder="Masukkan NIK / NIP / No Pegawai / SIM"
                  onChange={this.nik}
                />
              </div>
              <div className="w-full mt-1">
                <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                  Nama
                </label>
                <input
                  type="text"
                  className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                  placeholder="Masukkan Nama Lengkap"
                  onChange={this.nama}
                />
              </div>
              <div className="w-full mt-1">
                <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                  Username
                </label>
                <input
                  type="text"
                  className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                  placeholder="Masukkan Username"
                  onChange={this.username}
                />
              </div>
              <div className="w-full mt-1">
                <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                  Email
                </label>
                <input
                  type="text"
                  className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                  placeholder="Masukkan Email"
                  onChange={this.email}
                />
              </div>
              <div className="w-full mt-1">
                <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                  Password
                </label>
                <input
                  type="text"
                  className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                  placeholder="Masukkan Password"
                  onChange={this.password}
                />
              </div>
              <div className="w-full mt-3">
                <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                  Jenis Kelamin
                </label>
                <label className="inline-flex items-center align-middle">
                  <input
                    type="radio"
                    className="form-radio"
                    value="1"
                    name="jenis_kelamin"
                    onChange={this.jenis_kelamin}
                  />
                  <span className="ml-2 text-gray-800 block text-xs">
                    Laki - laki
                  </span>
                </label>
                <label className="inline-flex items-center align-middle ml-6">
                  <input
                    type="radio"
                    className="form-radio"
                    value="0"
                    name="jenis_kelamin"
                    onChange={this.jenis_kelamin}
                  />
                  <span className="ml-2 text-gray-800 block text-xs">
                    Perempuan
                  </span>
                </label>
              </div>
              <div className="w-full mt-3">
                <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                  Tanggal Lahir
                </label>
                <input
                  type="date"
                  name="birthday"
                  className="w-full pl-4 pr-4 my-0 py-2 rounded-full border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                  onChange={this.tanggal_lahir}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="w-full mt-3">
                <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                  No Telepon
                </label>
                <input
                  type="number"
                  min="0"
                  className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                  placeholder="Masukkan No Telepon"
                  onChange={this.no_telepon}
                />
              </div>
              <input
                type="submit"
                className={
                  this.state.simpleLoading
                    ? "btn-disabled px-4 flex items-center rounded-full py-2 mt-2 text-sm"
                    : "btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                }
                value={this.state.simpleLoading ? "Tunggu..." : "Simpan"}
                disabled={this.state.simpleLoading ? true : false}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalTambah}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        {/* modal Edit */}
        <Modal show={this.state.modal_edit} onHide={this.hideModalEdit}>
          <Modal.Header>Edit User</Modal.Header>
          {this.state.loading_edit ? (
            <UserEditSkeleton />
          ) : (
            <Modal.Body>
              <form onSubmit={this.update}>
                <div className="w-full mt-3">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                    No Pendaftaran
                  </label>
                  <input
                    type="number"
                    min="0"
                    className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                    placeholder="Masukkan (NIK / NIP / KK / No Pegawai)"
                    defaultValue={this.state.nik != "" ? this.state.nik : ""}
                    onChange={this.nik}
                  />
                </div>
                <div className="w-full mt-1">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                    Nama
                  </label>
                  <input
                    type="text"
                    className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                    placeholder="Masukkan Nama Lengkap"
                    defaultValue={this.state.nama != "" ? this.state.nama : ""}
                    onChange={this.nama}
                  />
                </div>
                <div className="w-full mt-1">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                    Username
                  </label>
                  <input
                    type="text"
                    className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                    placeholder="Masukkan Username"
                    defaultValue={
                      this.state.username != "" ? this.state.username : ""
                    }
                    onChange={this.username}
                  />
                </div>
                <div className="w-full mt-1">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                    Email
                  </label>
                  <input
                    type="email"
                    className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                    placeholder="Masukkan Email"
                    defaultValue={
                      this.state.email != "" ? this.state.email : ""
                    }
                    onChange={this.email}
                  />
                </div>
                <div className="w-full mt-1">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                    Password
                  </label>
                  <input
                    type="text"
                    className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                    placeholder="Masukkan Password"
                    defaultValue=""
                    onChange={this.password}
                  />
                </div>
                <div className="w-full mt-3">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                    Jenis Kelamin
                  </label>
                  <label className="inline-flex items-center align-middle">
                    <input
                      type="radio"
                      className="form-radio"
                      value="1"
                      name="jenis_kelamin"
                      onChange={this.jenis_kelamin}
                      defaultChecked={
                        this.state.jenis_kelamin == "1" ? true : false
                      }
                    />
                    <span className="ml-2 text-gray-800 block text-xs">
                      Laki - laki
                    </span>
                  </label>
                  <label className="inline-flex items-center align-middle ml-6">
                    <input
                      type="radio"
                      className="form-radio"
                      value="0"
                      name="jenis_kelamin"
                      onChange={this.jenis_kelamin}
                      defaultChecked={
                        this.state.jenis_kelamin == "0" ? true : false
                      }
                    />
                    <span className="ml-2 text-gray-800 block text-xs">
                      Perempuan
                    </span>
                  </label>
                </div>
                <div className="w-full mt-3">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                    Tanggal Lahir
                  </label>
                  <input
                    type="date"
                    name="birthday"
                    className="w-full pl-4 pr-4 my-0 py-2 rounded-full border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                    onChange={this.tanggal_lahir}
                    defaultValue={
                      this.state.tanggal_lahir != ""
                        ? this.state.tanggal_lahir
                        : ""
                    }
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div className="w-full mt-3">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                    No Telepon (Opsional)
                  </label>
                  <input
                    type="number"
                    min="0"
                    className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                    placeholder="Masukkan No Telepon (Opsional)"
                    defaultValue={
                      this.state.no_telepon != "" ? this.state.no_telepon : ""
                    }
                    onChange={this.no_telepon}
                  />
                </div>
                <input
                  type="submit"
                  className={
                    this.state.simpleLoading
                      ? "btn-disabled px-4 flex items-center rounded-full py-2 mt-2 text-sm"
                      : "btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                  }
                  value={this.state.simpleLoading ? "Tunggu..." : "Simpan"}
                  disabled={this.state.simpleLoading ? true : false}
                />
              </form>
            </Modal.Body>
          )}
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalEdit}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        {/* modal detail */}
        <Modal
          size="lg"
          show={this.state.modal_detail}
          onHide={this.hideModalDetail}
        >
          {this.state.loading_detail ? (
            <UserDetailSkeleton />
          ) : (
            <Modal.Body className="">
              <div className="flex flex-col w-full">
                <div
                  className={`w-full rounded-lg bg-1 mb-10`}
                  style={{ height: "10rem" }}
                >
                  <div
                    className="overflow-hidden rounded-lg"
                    style={{ height: "10rem" }}
                  >
                    <img
                      className=""
                      src={
                        this.state.data_detail.foto_sampul
                          ? `https://static.schoolmedia.id/public/assets/socmed/LA/cover/${this.state.data_detail.foto_sampul}`
                          : `${conf.uri_frontend}assets/img/cover-unavailable.svg`
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    className="flex flex-row px-4 pb-4 rounded-lg items-center w-full bg-transparent-grad relative"
                    style={{ bottom: "10rem", paddingTop: "6rem" }}
                  >
                    <img
                      className="rounded-full border-4 border-white shadow-xl"
                      style={{
                        width: "6rem",
                        height: "6rem",
                        objectFit: "cover",
                      }}
                      src={
                        this.state.data_detail.foto_profil
                          ? `https://static.schoolmedia.id/public/assets/socmed/LA/photo/${this.state.data_detail.foto_profil}`
                          : `${conf.uri_frontend}assets/img/profile-unavailable.png`
                      }
                    />
                    <h1 className="text-xl font-bold text-gray-800 ml-2">
                      {this.state.data_detail.nama
                        ? this.state.data_detail.nama
                        : "-"}
                    </h1>
                  </div>
                </div>
              </div>
              <Tabs
                defaultActiveKey="detail1"
                id="uncontrolled-tab-example"
                className="mt-4"
              >
                <Tab eventKey="detail1" title="Detail 1">
                  <div className="show-grid flex">
                    <div className="sm:w-full w-1/2">
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Token
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.token
                            ? this.state.data_detail.token
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          No Pendaftaran
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.kode_pendaftaran
                            ? this.state.data_detail.kode_pendaftaran
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Jenis Kelamin
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.jenis_kelamin != null
                            ? this.state.data_detail.jenis_kelamin != 1
                              ? "Perempuan"
                              : "Laki - laki"
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Tempat Lahir
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.tempat_lahir
                            ? this.state.data_detail.tempat_lahir
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Tanggal Lahir
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.tanggal_lahir
                            ? this.state.data_detail.tanggal_lahir
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="sm:w-full w-1/2">
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Agama
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.agama
                            ? this.state.data_detail.agama
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          No. Handphone
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.no_handphone
                            ? this.state.data_detail.no_handphone
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Golongan Darah
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.golongan_darah
                            ? this.state.data_detail.golongan_darah
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Pendidikan Terakhir
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.pendidikan_terakhir
                            ? this.state.data_detail.pendidikan_terakhir
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Motto
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.motto
                            ? this.state.data_detail.motto
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="detail2" title="Detail 2">
                  <div className="show-grid flex">
                    <div className="sm:w-full w-1/3">
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          No. KK
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.kk
                            ? this.state.data_detail.kk
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          No. NIK
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.nik
                            ? this.state.data_detail.nik
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          No. NPWP
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.npwp
                            ? this.state.data_detail.npwp
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          No. Paspor
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.paspor
                            ? this.state.data_detail.paspor
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Negara
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.negara
                            ? this.state.data_detail.negara
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="sm:w-full w-1/3">
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-900 block text-sm">
                          Alamat Tinggal
                        </label>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Provinsi
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.provinsi_tinggal
                              ? this.state.data_detail.provinsi_tinggal
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Kabupaten / Kota
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.kabupaten_tinggal
                              ? this.state.data_detail.kabupaten_tinggal
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Kecamatan
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.kecamatan_tinggal
                              ? this.state.data_detail.kecamatan_tinggal
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Desa
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.desa_tinggal
                              ? this.state.data_detail.desa_tinggal
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Kode Pos
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.kode_pos__tinggal
                              ? this.state.data_detail.kode_pos__tinggal
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            RW / RT
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.rw_tinggal
                              ? this.state.data_detail.rw_tinggal
                              : "-"}{" "}
                            /
                            {this.state.data_detail.rt_tinggal
                              ? ` ${this.state.data_detail.rt_tinggal}`
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Alamat
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.alamat_tinggal
                              ? this.state.data_detail.alamat_tinggal
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sm:w-full w-1/3">
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-900 block text-sm">
                          Alamat Domisili
                        </label>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Provinsi
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.provinsi_domisili
                              ? this.state.data_detail.provinsi_domisili
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Kabupaten / Kota
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.kabupaten_domisili
                              ? this.state.data_detail.kabupaten_domisili
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Kecamatan
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.kecamatan_domisili
                              ? this.state.data_detail.kecamatan_domisili
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Desa
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.desa_domisili
                              ? this.state.data_detail.desa_domisili
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Kode Pos
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.kode_pos__domisili
                              ? this.state.data_detail.kode_pos__domisili
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            RW / RT
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.rw_domisili
                              ? this.state.data_detail.rw_domisili
                              : "-"}{" "}
                            /
                            {this.state.data_detail.rt_domisili
                              ? ` ${this.state.data_detail.rt_domisili}`
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Alamat
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.alamat_domisili
                              ? this.state.data_detail.alamat_domisili
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
              <div className="show-grid flex mt-4 border-t">
                <div className="sm:w-full w-1/2 px-2">
                  <p className="my-2 ml-2 text-gray-900 block text-sm font-bold">
                    Foto KTP
                  </p>
                  <img
                    className="w-full"
                    style={{
                      height: "300px",
                      objectFit: "none",
                      objectPosition: "center",
                    }}
                    src={
                      this.state.data_detail.foto_ktp
                        ? `https://static.schoolmedia.id/public/assets/socmed/LA/ktp/${this.state.data_detail.foto_ktp}`
                        : `${conf.uri_frontend}assets/img/img-unavailable.png`
                    }
                  />
                </div>
                <div className="sm:w-full w-1/2 px-2">
                  <p className="my-2 ml-2 text-gray-900 block text-sm font-bold">
                    Foto KTP & Wajah
                  </p>
                  <img
                    className="w-full"
                    style={{
                      height: "300px",
                      objectFit: "none",
                      objectPosition: "center",
                    }}
                    src={
                      this.state.data_detail.foto_ktp_dan_wajah
                        ? `https://static.schoolmedia.id/public/assets/socmed/LA/ktp-face/${this.state.data_detail.foto_ktp_dan_wajah}`
                        : `${conf.uri_frontend}assets/img/img-unavailable.png`
                    }
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="rounded-lg w-1/2 mx-auto">
                  <p className="my-2 ml-2 text-gray-900 block text-sm font-bold">
                    Foto Doc. Pendukung
                  </p>
                  <img
                    className="w-full"
                    style={{
                      height: "300px",
                      objectFit: "none",
                      objectPosition: "center",
                    }}
                    src={
                      this.state.data_detail.foto_doc_pendukung
                        ? `https://static.schoolmedia.id/public/assets/socmed/LA/img-support/${this.state.data_detail.foto_doc_pendukung}`
                        : `${conf.uri_frontend}assets/img/img-unavailable.png`
                    }
                  />
                </div>
              </div>
            </Modal.Body>
          )}
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalDetail}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        {/* Cetak Modal */}
        <Modal
          size="lg"
          show={this.state.modal_cetak}
          onHide={this.hideModalCetak}
        >
          <Modal.Header>Cetak User Lainnya</Modal.Header>
          <Modal.Body>
            <div className="flex justify-between items-center w-full px-2 py-2">
              <div className="w-1/2 flex items-center justify-start">
                <input
                  type="search"
                  className="w-2/3 pl-4 pr-1 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 text-sm "
                  placeholder="Cari nama"
                  id="search"
                  onChange={(event) => {
                    this.setState(
                      {
                        queryToken: `&search=${event.target.value}
                                  &paginate=${this.state.mode_show_token}`,
                        searchToken: event.target.value,
                      },
                      function() {
                        this.dtt.loading();
                        this.dtt.fetchEntities(this.state.queryToken);
                      }
                    );
                  }}
                />
                <div className="ml-2 w-1/3 pl-3 pr-3 py-1 rounded-full flex justify-between items-center  border border-gray-500 focus:outline-none focus:shadow-outline  text-sm ">
                  <span className="text-sm text-gray-800">Pilih semua</span>
                  <input
                    type="checkbox"
                    className="select-all"
                    onChange={this.pilihSemua.bind(this)}
                  />
                </div>
              </div>
              <div className="w-1/2 text-right flex items-center">
                <div className="w-full ml-1 flex flex-wrap text-right items-center justify-end">
                  <div className="mr-0 flex text-right items-center">
                    <span className="text-sm  ml-auto text-gray-800 px-1 mb-2">
                      Tampilkan :{" "}
                    </span>
                    <button
                      onClick={this.showModeToken.bind(this, 10)}
                      className={`${
                        this.state.mode_show_token == 10
                          ? `btn-schoolmedia`
                          : `bg-gray-400`
                      } px-1 flex items-center rounded-full text-white py-1 text-xs mr-1`}
                    >
                      10
                    </button>
                    <button
                      onClick={this.showModeToken.bind(this, 50)}
                      className={`${
                        this.state.mode_show_token == 50
                          ? `btn-schoolmedia`
                          : `bg-gray-400`
                      } px-1 flex items-center rounded-full text-white py-1 text-xs mr-1`}
                    >
                      50
                    </button>
                    <button
                      onClick={this.showModeToken.bind(this, 100)}
                      className={`${
                        this.state.mode_show_token == 100
                          ? `btn-schoolmedia`
                          : `bg-gray-400`
                      } px-1 flex items-center rounded-full text-white py-1 text-xs mr-1`}
                    >
                      100
                    </button>
                    <button
                      onClick={this.showModeToken.bind(this, 200)}
                      className={`${
                        this.state.mode_show_token == 200
                          ? `btn-schoolmedia`
                          : `bg-gray-400`
                      } px-1 flex items-center rounded-full text-white py-1 text-xs mr-1`}
                    >
                      200
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div id="cetakToken">
              {this.showTableToken()}
              {this.state.loading_saveprint ? (
                <button
                  className="btn-schoolmedia px-4 flex items-center rounded-full text-white mt-2 py-2 text-sm"
                  disabled={this.state.loading_saveprint == true ? true : false}
                >
                  <UserButtonLoading />
                </button>
              ) : (
                <input
                  type="button"
                  onClick={this.printToken.bind(this)}
                  className="btn-schoolmedia px-4 flex items-center rounded-full text-white mt-2 py-2 text-sm"
                  value="Simpan"
                />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalCetak}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        {/* Upload / Import */}
        <Modal
          size="lg"
          show={this.state.modal_import}
          onHide={this.hideModalImport}
        >
          <Modal.Header>Upload Data</Modal.Header>
          <Modal.Body>
            <div className="w-full mt-3 flex flex-row items-center">
              <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3 mr-2">
                Download template excelnya dulu sebelum upload data
              </label>
              <a
                href={`${process.env.REACT_APP_URI_BACKEND}storage/02-user-management/format/contoh-import-data-lainnya.xlsx`}
                download
              >
                <button className="btn-default flex text-xs px-4 py-2 mr-1 rounded-full items-center">
                  Download
                  <i className="bx bx-download ml-1"></i>
                </button>
              </a>
              <a
                href="https://help.schoolmedia.id/"
                target="_blank"
                rel="noreferrer"
              >
                <button className="btn-default flex text-xs px-4 py-2 mr-1 rounded-full items-center">
                  Tutorial Upload
                  <i className="bx bx-info-circle ml-1"></i>
                </button>
              </a>
            </div>
            <form onSubmit={this.import}>
              <div className="w-full mt-3">
                <label className="mb-2 ml-2 text-gray-800 block text-sm mt-3">
                  File Excel
                </label>
                <input
                  type="file"
                  className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                  onChange={this.import_file}
                />
              </div>
              <input
                type="submit"
                className={
                  this.state.simpleLoading
                    ? "btn-disabled px-4 flex items-center rounded-full py-2 mt-2 text-sm"
                    : "btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                }
                value={this.state.simpleLoading ? "Tunggu..." : "Simpan"}
                disabled={this.state.simpleLoading ? true : false}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalImport}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        {/* Preview Import */}
        <Modal
          size="lg"
          show={this.state.modal_preview_import}
          onHide={this.hideModalPrevImport}
        >
          <Modal.Header>Preview Import</Modal.Header>
          <Modal.Body>
            <form onSubmit={this.simpanImport}>
              <div className="table-responsive">
                <table className="table table-bordered font-thin text-sm">
                  <thead className="text-center">
                    <th>KODE PENDAFTARAN</th>
                    <th>NAMA</th>
                    <th>JENIS KELAMIN</th>
                    <th>TANGGAL LAHIR</th>
                    <th>NO. HP</th>
                    <th>USERNAME</th>
                    <th>EMAIL</th>
                    <th>PASSWORD</th>
                    <th>NAMA FILE FOTO PROFIL</th>
                  </thead>
                  <tbody>
                    {this.state.data_preview.map((person, index) => (
                      <tr className={person.duplicated ? 'table-danger' : ''} key={index}>
                        <td>
                          <div className="text-sm">
                            {person.kode_pendaftaran}
                          </div>
                        </td>
                        <td>
                          <div className="text-sm">{person.nama}</div>
                        </td>
                        <td>
                          <div className="text-sm">
                            {
                              this.state.jenis_kelamin_text[
                                person.jenis_kelamin
                              ]
                            }
                          </div>
                        </td>
                        <td>
                          <div className="text-sm">{person.tanggal_lahir}</div>
                        </td>
                        <td>
                          <div className="text-sm">{person.no_handphone}</div>
                        </td>
                        <td>
                          {" "}
                          <div className="text-sm">{person.username}</div>
                        </td>
                        <td>
                          {" "}
                          <div className="text-sm">{person.email}</div>
                        </td>
                        <td>
                          {" "}
                          <div className="text-sm">{person.password}</div>
                        </td>
                        <td>
                          <div className="text-sm">{person.foto_profil}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <input
                type="submit"
                className={
                  this.state.simpleLoading
                    ? "btn-disabled px-4 flex items-center rounded-full py-2 mt-2 text-sm"
                    : "btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                }
                value={this.state.simpleLoading ? "Tunggu..." : "Simpan"}
                disabled={this.state.simpleLoading ? true : false}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalPrevImport}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        <div className=" shadow-md  mx-auto py-2 mt-2 rounded-lg bg-white border sm-container">
          {this.state.institusi_id_select != "" ? (
            <div className="w-full flex mt-3 mb-2 px-4 pb-2">
              <div className="w-1/2">
                <span className="text-sm text-gray-800 px-1 mb-2">Cari :</span>
                <div className="relative w-2/3 flex flex-row">
                  <input
                    type="search"
                    id="search"
                    className="w-full pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                    placeholder="Cari kode pendaftaran, nama, username, token"
                    onChange={this.searchText}
                  />
                  <div className="absolute top-0 left-0 inline-flex items-center py-2 px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-gray-400"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        stroke="none"
                      ></rect>
                      <circle cx="10" cy="10" r="7"></circle>
                      <line x1="21" y1="21" x2="15" y2="15"></line>
                    </svg>
                  </div>
                </div>
                <div className="relative w-2/3 mt-2 flex flex-row">
                  <Select
                    styles={selectStyles}
                    className="react-select__control w-100"
                    placeholder="Pilih Status"
                    isDisabled={this.state.disabledFilter}
                    defaultValue={
                      this.state.selectStatus != ""
                        ? {
                            value: this.state.selectStatus,
                            label: this.state.selectStatus,
                          }
                        : ""
                    }
                    options={[
                      { value: "Active", label: "Active" },
                      { value: "Pending", label: "Pending" },
                    ]}
                    onChange={(e) => {
                      this.changeStatus(e);
                    }}
                  />
                </div>
                <span className="text-sm text-gray-800 px-1 mb-2 flex items-center">
                  Total data :{" "}
                  {this.state.totalUser != "" ? (
                    this.state.totalUser
                  ) : (
                    <span className="w-10 mt-1 ml-2">
                      {" "}
                      <Skeleton height={10} className="bg-gray-400" />{" "}
                    </span>
                  )}
                </span>
              </div>
              <div className="w-1/2 text-right">
                <span className="text-sm text-gray-800 px-1 mb-2">
                  Tampilkan
                </span>
                <div className="w-1/2 ml-auto flex flex-wrap text-right items-center justify-end">
                  <div className="mr-0 flex text-right ">
                    <button
                      onClick={this.showMode.bind(this, 10)}
                      className={`${
                        this.state.mode_show == 10
                          ? `btn-schoolmedia`
                          : `bg-gray-400`
                      } px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}
                    >
                      10
                    </button>
                    <button
                      onClick={this.showMode.bind(this, 50)}
                      className={`${
                        this.state.mode_show == 50
                          ? `btn-schoolmedia`
                          : `bg-gray-400`
                      } px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}
                    >
                      50
                    </button>
                    <button
                      onClick={this.showMode.bind(this, 100)}
                      className={`${
                        this.state.mode_show == 100
                          ? `btn-schoolmedia`
                          : `bg-gray-400`
                      } px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}
                    >
                      100
                    </button>
                    <button
                      onClick={this.showMode.bind(this, 200)}
                      className={`${
                        this.state.mode_show == 200
                          ? `btn-schoolmedia`
                          : `bg-gray-400`
                      } px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}
                    >
                      200
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full flex justify-between">
              <div className="w-1/2 px-2">
                <div className="w-1/2 px-2">
                  <Skeleton height={20} className="bg-gray-400" />
                  <Skeleton height={20} className="bg-gray-400" />
                </div>
              </div>
              <div className="w-1/2 px-2 flex justify-end">
                <div className="w-1/2 px-2">
                  <Skeleton height={20} className="bg-gray-400" />
                  <Skeleton height={20} className="bg-gray-400" />
                </div>
              </div>
            </div>
          )}

          <div className="px-2 py-1">
            <DataTable
              ref={(dt) => {
                this.dt = dt;
              }}
              action={{
                jenis_kelamin: (col, data) => {
                  var jekel = "";
                  if (col.jenis_kelamin == 1) {
                    jekel = "Laki - Laki";
                  }

                  if (col.jenis_kelamin == 0) {
                    jekel = "Perempuan";
                  }

                  if (col.jenis_kelamin == null) {
                    jekel = "Belum diketahui";
                  }
                  return <p className="text-sm">{jekel}</p>;
                },

                created_at: (col, data) => {
                  if (col.created_at !== null) {
                    var date = "";
                    var month = "";
                    var year = "";
                    date = col.created_at.substr(8, 2);
                    month = col.created_at.substr(5, 2);
                    year = col.created_at.substr(0, 4);

                    return (
                      <p className="text-sm">
                        {date + "-" + month + "-" + year}
                      </p>
                    );
                  } else {
                    return <p className="text-sm">Tidak Diketahui</p>;
                  }
                },

                status: (col, data) => {
                  var statusUsr = "";
                  var tooltip = "";
                  var warna = "";
                  var ikon = "";
                  var width = "";

                  if (col.status == "Active") {
                    statusUsr = "Aktif";
                    tooltip = "User sudah teraktivasi";
                    warna = "bg-green-600";
                    ikon = "bx-check";
                    width = "w-16";
                  } else if (col.status == "Non-Active") {
                    statusUsr = "Non-Aktif";
                    tooltip = "User telah dinon-aktifkan";
                    warna = "bg-red-600";
                    ikon = "bx-x";
                    width = "w-auto";
                  } else {
                    statusUsr = "Pending";
                    tooltip =
                      "User belum aktivasi di SSO dan klik link konfirmasi di email";
                    warna = "bg-gray-600";
                    ikon = "bx-error";
                    width = "w-20";
                  }
                  return (
                    <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
                      <div
                        className={`py-1 ${width} px-1 ${warna} text-white text-xs rounded-full flex cursor`}
                      >
                        <i
                          className={`bx ${ikon} text-white font-bold text-sm mx-1`}
                        ></i>
                        {statusUsr}
                      </div>
                    </OverlayTrigger>
                  );
                },

                token: (col, data) => {
                  var button = "";
                  if (col.token === null) {
                    button = (
                      <div>
                        <div className="text-sm" id={"token" + data.id}></div>
                        <button
                          onClick={this.generate_token.bind(this, data.id)}
                          id={"myButton" + data.id}
                          className="font-medium btn-default text-gray-700 px-4 py-2 mr-1 rounded-full text-sm"
                          disabled={
                            this.state.loading_token === data.id ? true : false
                          }
                        >
                          {this.state.loading_token === data.id ? (
                            <UserButtonLoading color="text-gray-800" />
                          ) : (
                            "Token"
                          )}
                        </button>
                      </div>
                    );
                  } else {
                    button = <div className="text-sm">{col.token}</div>;
                  }
                  return button;
                },
                emaila: (col, data) => {
                  if (data?.email == null || data?.email == "") {
                    var value = "-";
                  } else {
                    var value = data.email;
                  }
                  return (
                    <>
                      <small className="text-small">{value}</small>
                    </>
                  );
                },
                usernamea: (col, data) => {
                  if (data?.username == null || data?.username == "") {
                    var value = "-";
                  } else {
                    var value = data.username;
                  }
                  return (
                    <>
                      <small className="text-small">{value}</small>
                    </>
                  );
                },

                aksi: (col, data) => {
                  return (
                    <div className="text-gray-700 px-4 py-1 flex items-center">
                      <button
                        onClick={this.showModalDetail.bind(this, data.id)}
                        className="btn-blue text-lg text-white py-1 px-2 rounded-full font-medium mr-1"
                      >
                        <i className="bx bxs-user-detail"></i>
                      </button>
                      <button
                        onClick={this.showModalEdit.bind(this, data.id)}
                        className="btn-yellow text-lg text-white py-1 px-2 rounded-full font-medium mr-1"
                      >
                        <i className="bx bxs-edit-alt"></i>
                      </button>
                      <button
                        onClick={this.delete.bind(this, data.id)}
                        className={`${
                          this.state.loading_delete === true
                            ? `btn-gray`
                            : `btn-red`
                        } text-lg text-white py-1 px-2 rounded-full font-medium`}
                        disabled={
                          this.state.loading_delete === true ? true : false
                        }
                      >
                        <i className="bx bx-trash"></i>
                      </button>
                      {this.state.loading_check == false ? (
                        <input
                          type="checkbox"
                          name="check_data[]"
                          id={data.id}
                          value={data.id}
                          onChange={this.selectOne}
                          className="select-checkbox ml-2"
                          defaultChecked={this.state.checkAll}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                },
              }}
              url={this.state.url_table + this.state.institusi_id_select}
              columns={[
                {
                  index: "no_index",
                  label: "No",
                },
                {
                  index: "kode_pendaftaran",
                  label: "KODE PENDAFTARAN",
                },
                {
                  index: "nama",
                  label: "NAMA",
                },
                {
                  index: "usernamea",
                  label: "USERNAME",
                },
                {
                  index: "emaila",
                  label: "EMAIL",
                },
                {
                  index: "jenis_kelamin",
                  label: "JENIS KELAMIN",
                },
                {
                  index: "no_handphone",
                  label: "NO HP",
                },
                {
                  index: "tanggal_lahir",
                  label: "TANGGAL LAHIR",
                },
                {
                  index: "created_at",
                  label: "TANGGAL PENDAFTARAN",
                  sort: "desc",
                },
                {
                  index: "status",
                  label: "STATUS",
                },
                {
                  index: "token",
                  label: "TOKEN",
                },
                {
                  index: "aksi",
                  label: "AKSI",
                },
              ]}
            />
          </div>
        </div>

        <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
          <div className="w-full text-right flex justify-end px-4">
            <div className="flex flex-wrap items-baseline px-3 justify-end">
              <p>Pilih user lainnya dan klik tombol</p>
              {this.state.checkAll == false ? (
                <button
                  className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                  onClick={this.checkAll}
                >
                  Pilih Semua
                </button>
              ) : (
                <button
                  className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                  onClick={this.unCheckAll}
                >
                  Tidak Pilih Semua
                </button>
              )}
              <button
                onClick={this.deleteMulti}
                className="btn-green px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                disabled={this.state.loading_delete == true ? true : false}
              >
                {this.state.loading_delete ? <UserButtonLoading /> : "Hapus"}
              </button>
              <button
                onClick={this.sendMailActivation}
                className="btn-blue px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                disabled={this.state.loading_delete == true ? true : false}
              >
                {this.state.loading_mail ? <UserButtonLoading /> : "Send Email"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
